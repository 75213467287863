import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact.component';
import { HeaderComponent } from 'src/app/header/header/header.component';

const routes: Routes = [
  {
      path: '',
      component: ContactComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  declarations: [ContactComponent],
})
export class ContactModule { }
