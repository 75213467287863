import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/header/header/header.component';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path     : '',
      component: HomeComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
