import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home/home.component';
import { ContactComponent } from './contact/contact/contact.component';
import { HomeModule } from './home/home/home.module';
import { ContactModule } from './contact/contact/contact.module';


const routes: Routes = [
  { path: '', loadChildren: () => import('../app/home/home/home.module').then(m => m.HomeModule)},
  { path: 'contact', loadChildren: () => import('../app/contact/contact/contact.module').then(m => m.ContactModule)},
  { path: '**', loadChildren: () => import('../app/home/home/home.module').then(m => m.HomeModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), HomeModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
