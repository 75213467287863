<header class="header" role="banner">
  <div class="row row--justified row--middle">
    <a class="" href="#" routerLink="/" style="padding-top: 10px;">
      <img src="assets/images/logo.png" alt="Roberto Suárez Full Stack Developer Angular/Ionic Web/Mobile."
        style="width: 280px; height:auto;">
    </a>
    <nav class="nav-primary">
      <ul class="menu js-menu">
        <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-12"><a
            href="/assets/pdf/CV-Roberto-A-Suarez-Gil.pdf" target="_blank">Mi C.V</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-9"><a href="/contact"
            target="blank">Contáctame</a></li>
      </ul>
    </nav>

  </div>
</header>

<main id="main" role="main" style="background-color: #f6f6f6">
  <div class="hero" id="hero"
    style="background-image: url('assets/images/bg.jpg'); background-position: center; background-repeat: no-repeat; background-size: cover;">
    <div class="container--wide">
      <div class="row row--middle">
        <div class="hero__content">
          <h1><b>Hola! </b>Soy <b>Roberto Suárez</b><span class="type-it"></span><i></i></h1>
          <p>Tengo mucho tiempo en Internet, la música rock en mis audífonos, programador experimentado,
            amante de las guitarras y el diseño.
          </p> <a class="" href="/contact">Contáctame</a>
        </div>

      </div>
    </div>
  </div>
  <section class="module module__panel">
    <div class="module__panel--to row row--middle row--center dark" style="background-color: #ff3a49">
      <div class="container--narrow">
        <div class="column column-m-12 column-d-12">
          <div class="module__content">
            <h2>Angular/Ionic Web/Mobile.</h2>
            <p> Cuento con más de <b>12 años de experiencia</b> en el desarrollo de plataformas tecnológicas
              Web y Móvil,
              he trabajado en proyectos de gran envergadura pasando por varias tecnologías, este recorrido
              me ha permitido
              estar involucrado en proyectos que van desde sistemas analíticos, complejos sistemas de
              Geo-Localización,
              motores de búsqueda, desarrollo de plataformas 2.0, aplicaciones móviles nativas para
              android, aplicaciones hibridas con Ionic, etc.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module module__grid powers parallax" style="background-color: #01adef">
    <div class="container">
      <div class="row row--spaced">
        <div class="column column-m-12 column-t-4 column-d-3">
          <div class="module__grid__image">
            <div class="module__grid__image__main"></div>
            <div class="module__grid__image__hover"></div>
          </div>
          <div class="module__grid__content">
            <h3>Angular/Web</h3>
            <p>Aplicaciones de una sola página (SPA-Single Page Application).</p>
            <p>Aplicaciones web modernas, complejas, con capacidad de respuesta y escalables.</p>
            <p>Arquitectura de aplicaciones con Angular 8+.</p>
            <p>Desarrollo mucho más flexible y modular.</p>
          </div>
        </div>
        <div class="column column-m-12 column-t-4 column-d-3">
          <div class="module__grid__image">
            <div class="module__grid__image__main"></div>
            <div class="module__grid__image__hover"></div>
          </div>
          <div class="module__grid__content">
            <h3>Ionic/Mobile</h3>
            <p>Aplicaciones móviles para IOS, Android, tabletas y aplicaciones web.</p>
            <p>Uso de recursos nativos del dispositivo.</p>
            <p>Google APIs.</p>
            <p>Facebook APIs.</p>
          </div>
        </div>
        <div class="column column-m-12 column-t-4 column-d-3">
          <div class="module__grid__image">
            <div class="module__grid__image__main"></div>
            <div class="module__grid__image__hover"></div>
          </div>
          <div class="module__grid__content">
            <h3>Node.js</h3>
            <p>Backend server</p>
            <p>Servicios REST</p>
            <p>Json web tokens</p>
            <p>Sockets</p>
            <p>Google Services</p>
            <p>Google APIs</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="daily-planet">
    <div class="container">
      <div class="row">
        <div class="column column-m-12">
          <h3>Aplicaciones Móvil</h3>
        </div>
      </div>
      <div class="blog__posts row">
        <div class="blog__posts__post column column-m-12 column-t-6">
          <div class="blog__posts__post__inner">
            <h2 itemprop="headline">Ionic /Mobile</h2>
            <span style="font-size: 16px;color: #484848;"> Desarrollo de aplicaciones móviles nativas Android y
              aplicaciones hibridas con ionic, este último es un framework que nos permite tomar nuestro conocimiento de
              Angular y crear aplicaciones multiplataforma
              que pueden correr en Android, IOS, convertirse en una PWA o crear una aplicación web, todo con un mismo
              repositorio de código.</span>
            <p><a class="" href="/contact">Contáctame</a></p>
          </div>
          <div class="row">
            <div class="olumn column-m-12 column-t-6">
              <img src="assets/images/public_1.png">
            </div>
            <div class="olumn column-m-12 column-t-6">
              <img src="assets/images/seguimiento.png">
            </div>
          </div>
        </div>
        <div class="blog__posts__post column column-m-12 column-t-6">
          <div class="blog__posts__post__inner">
            <img src="assets/images/Home-Who@2x-2.png" alt="Who we are">
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="module module__panel">
    <div class="module__panel--it row row--middle row--collapse">
      <div class="module__image column column-m-12 column-d-6"> <img src="assets/images/web.png" alt="" /></div>
      <div class="column column-m-12 column-d-6">
        <div class="module__content">
          <h3>Aplicaciones Web</h3>
          <p>Desarrollo de aplicaciones web basadas en Angular (framework) de front-end impulsado por Google.
            Creado para desarrollar aplicaciones web, aplicaciones móviles o realizar procesos del lado del servidor
            utilizando NodeJS.
          </p>
          <p><a class="" href="/contact">Contáctame</a></p>
          <img src="assets/images/prueba1.jpg">
        </div>
      </div>
    </div>
  </section>
  <div class="work">
    <h3>Últimos Proyectos</h3>
    <div class="cases row">
      <div class="row row--justified work__posts">
        <div class="work__posts__post"><a href="https://encomana.cl/" target="_blank">
            <div class="work-image"> <img width="1400" height="1000" src="assets/images/app1.png"
                class="attachment-work-item size-work-item wp-post-image" alt=""
                srcset="assets/images/app1.png 1400w, assets/images/app1.png 700w"
                sizes="(max-width: 1400px) 100vw, 1400px" /></div>
            <div class="work-image"> <img width="1400" height="1000" src="assets/images/app1-1.png"
                class="attachment-work-item size-work-item wp-post-image" alt=""
                srcset="assets/images/app1-1.png 1400w, assets/images/app1-1.png 700w"
                sizes="(max-width: 1400px) 100vw, 1400px" /></div>
            <div class="work-title"> <span>Encomana - ahora los envíos son hechos por particulares</span></div>
            <span style="font-size: 14px; color: #484848">Plataforma Movíl/web para Pymes que les ayudará con la gestion
              de envíos.</span>
          </a></div>
        <div class="work__posts__post"><a href="https://Jelmi.cl">
            <div class="work-image"> <img width="700" height="500" src="assets/images/app2.png"
                class="attachment-work-item size-work-item wp-post-image" alt="" /></div>
            <div class="work-image"> <img width="700" height="500" src="assets/images/app2-1.png"
                class="attachment-work-item size-work-item wp-post-image" alt="" /></div>
            <div class="work-title"> <span>Jelmi.cl</span></div>
            <span style="font-size: 14px; color: #484848">Plataforma movíl 2.0 para buscar y compartir información de
              salud mental.</span>
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

      </div>
    </div>
  </div>
</main>

<footer class="site-footer" role="contentinfo">
  <div class="footer-top row row--spaced">
    <div class="column column-m-12 column-t-6 column-d-3">
      <div class="menu-main-menu-container">
        <ul id="menu-main-menu-1" class="menu">
          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-12"><a
              href="/assets/pdf/CV-Roberto-A-Suarez-Gil.pdf" target="_blank">Mi C.V</a></li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-9"><a
              href="/contact">Contáctame</a></li>
        </ul>
      </div>
    </div>
    <div class="column column-m-12 column-t-6 column-d-3">
      <div class="textwidget">
        <h4>¿Cómo te puedo ayudar?</h4>
        <p>La diferencia entre las buenas ideas exitosas y las buenas ideas que no tienen éxito radica en que las
          exitosas son las que se llevan a cabo con las personas correctas.</p>
      </div>
    </div>
    <div class="column column-m-12 column-t-6 column-d-3">
      <div class="textwidget">
        <h4>Quiero ayudarte!</h4>
        <p>Con mi asesoría en Diseño Web puedo brindarte la posibilidad de contar con una imagen acorde a los estándares
          actuales, tu idea necesita lucir espectacular y es allí dónde seré tu aliado potenciando tus ideas..</p>
      </div>
    </div>
    <div class="column column-m-12 column-t-6 column-d-3">
      <div class="textwidget">
        <p><a href="https://www.linkedin.com/in/rsuarezgil/" target="_blank">Linkedin</a><br>
        </p>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="row">
      <div class="column column-m-12 column-t-6">
        <p>© robertosuarez.cl 2023. All Rights Reserved.</p>
      </div>
      <div class="column column-m-12 column-t-6">
        <p></p>
      </div>
    </div>
  </div>
</footer>

<script type="text/javascript" defer src="assets/js/autoptimize_ff2f28511bc57758fe0bb50361aba80b.js"></script>