<header class="header" role="banner">

    <div class="row row--justified row--middle">
        <a class="" href="#" routerLink="/" style="padding-top: 10px;">
            <img src="assets/images/logo.png" alt="Roberto Suárez Full Stack Developer Angular/Ionic Web/Mobile."
                style="width: 280px; height:auto;">
        </a>
        <nav class="nav-primary">
            <ul class="menu js-menu">
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-12"><a
                        href="/assets/pdf/CV-Roberto-A-Suarez-Gil.pdf" target="_blank">Mi C.V</a></li>
            </ul>
        </nav>

    </div>
</header>

<main id="main" role="main">

    <section class="module module__panel">
        <div class="module__panel--in row row--middle row--collapse">
            <div class="module__bg"></div>

            <div class="column column-m-12 column-d-6">
                <div class="module__content" style="background-color: #fff;">
                    <div class="module__content__inner" style="background-color: #fff;">
                        <h1>¿Qué necesitas?</h1>
                        <p><b>¿Desarrollo de aplicaciones web?</b> <br /> te puedo ayudar con aplicaciones basadas en
                            Angular (framework) de front-end impulsado por Google. Creado para desarrollar aplicaciones
                            web, aplicaciones móviles o realizar procesos del lado del servidor utilizando NodeJS.</p>
                        <p><b>¿Desarrollo de aplicaciones móviles?</b> <br />te puedo ayudar con aplicaciones hibridas
                            con ionic, este último es un framework que nos permite tomar nuestro conocimiento de Angular
                            y crear aplicaciones multiplataforma que pueden correr en Android, IOS, convertirse en una
                            PWA o crear una aplicación web, todo con un mismo repositorio de código.</p>
                    </div>
                    <div align="center">
                        <a href="https://wa.me/56956029779" target="_blank"><img src="assets/images/boton-whatsapp.png"
                                style="width: 240px; margin-top: 80px"></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="module module__panel">
        <div class="module__panel--to row row--middle row--center Array">

        </div>
    </section>
</main>